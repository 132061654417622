var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"birthday-container"},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"birthday-background-video","poster":"/images/bg/transparent.png"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/videos/birthday/birthday-bg-video.mp4"),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]),_c('div',{staticClass:"content"},[(_vm.accountTitle)?_c('p',{directives:[{name:"animate-letters",rawName:"v-animate-letters",value:({
        animationName: 'letterFadeIn',
        delayForNextLetter: 0.12,
        durationOfEachLetter: 0.35,
        timingFunction: 'ease',
      }),expression:"{\n        animationName: 'letterFadeIn',\n        delayForNextLetter: 0.12,\n        durationOfEachLetter: 0.35,\n        timingFunction: 'ease',\n      }"}],staticClass:"customer-birthday-wish"},[_vm._v(" "+_vm._s(_vm.accountTitle)+" AİLESİ OLARAK DOĞUM GÜNÜNÜZÜ KUTLARIZ! ")]):_vm._e(),_c('h3',{directives:[{name:"animate-letters",rawName:"v-animate-letters",value:({
        animationName: 'letterFadeIn',
        delayForNextLetter: 0.14,
        durationOfEachLetter: 0.45,
      }),expression:"{\n        animationName: 'letterFadeIn',\n        delayForNextLetter: 0.14,\n        durationOfEachLetter: 0.45,\n      }"}],staticClass:"happy-birthday-text"},[_vm._v(" İyi ki doğdun ")]),(_vm.isDescriptionShow)?_c('h1',{directives:[{name:"animate-letters",rawName:"v-animate-letters",value:({
        animationName: 'letterFadeIn',
        delayForNextLetter: 0.14,
        durationOfEachLetter: 0.45,
      }),expression:"{\n        animationName: 'letterFadeIn',\n        delayForNextLetter: 0.14,\n        durationOfEachLetter: 0.45,\n      }"}],staticClass:"client-description"},[_vm._v(" "+_vm._s(_vm.birthdayData.description.toUpperCase())+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }