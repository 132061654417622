<template>
  <div class="birthday-container">
    <video autoplay muted loop id="birthday-background-video" poster="/images/bg/transparent.png">
      <source
        src="../assets/videos/birthday/birthday-bg-video.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <div class="content">
      <p
        v-if="accountTitle"
        class="customer-birthday-wish"
        v-animate-letters="{
          animationName: 'letterFadeIn',
          delayForNextLetter: 0.12,
          durationOfEachLetter: 0.35,
          timingFunction: 'ease',
        }"
      >
        {{ accountTitle }} AİLESİ OLARAK DOĞUM GÜNÜNÜZÜ KUTLARIZ!
      </p>
      <h3
        class="happy-birthday-text"
        v-animate-letters="{
          animationName: 'letterFadeIn',
          delayForNextLetter: 0.14,
          durationOfEachLetter: 0.45,
        }"
      >
        İyi ki doğdun
      </h3>
      <h1
        v-if="isDescriptionShow"
        class="client-description"
        v-animate-letters="{
          animationName: 'letterFadeIn',
          delayForNextLetter: 0.14,
          durationOfEachLetter: 0.45,
        }"
      >
        {{ birthdayData.description.toUpperCase() }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import accountStorage from "@/storage/modules/account";
import jwtHelper from "../shared/jwt-helper";
export default {
  name: "birthday",
  props: ["data"],
  data() {
    return {
      fileUrl: "/images/bg/birthday.png",
      isDescriptionShow: false,
      accountTitle: "",
    };
  },
  computed: {
    ...mapGetters("birthday", ["birthdayData"]),
    ...mapGetters(["logo"]),
  },
  methods: {
    ...mapActions(["setNextPlaylist"]),
    startTimer() {
      if (this.birthdayData.image) {
        this.fileUrl =
          process.env.VUE_APP_BASE_CDN_URL +
          "/" +
          this.birthdayData.image.fileName;
      } else {
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + "/" + this.logo;
      }
      setTimeout(() => {
        this.setNextPlaylist();
      }, (this.birthdayData.displaySecond || 30) * 1000);
    },
    showDescription() {
      setTimeout(() => {
        this.isDescriptionShow = true;
      }, 2000);
    },
  },
  async created() {
    var accountId = jwtHelper.getAccountId();
    var account = await accountStorage.getAccountById(accountId);
    this.accountTitle = account ? account.title : "";
    this.startTimer();
    this.showDescription();
  },
};
</script>

<style lang="scss">
.birthday-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  #birthday-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .content {
    text-align: center;
    white-space: nowrap;
    .customer-birthday-wish {
      position: absolute;
      left: 50%;
      top: 10%;
      transform: translate(-50%, -50%);
      font-size: 2.5vw;
      font-family: Ubuntu;
      padding: 2px 20px;
      border-radius: 10px;
      background-color: #9b6c1b;
      color: #fffefd;
    }
    .happy-birthday-text {
      position: absolute;
      left: 50%;
      top: 28%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-family: Noto Serif Display;
      font-weight: bold;
      font-style: italic;
      color: #fffefd;
      text-shadow: 10px 10px 30px black;
    }
    .client-description {
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-family: TheSeasons;
      font-weight: bolder;
      background-color: #9b6c1b;
      color: #fffefd;
      padding: 2px 60px;
      border-radius: 40px;
      letter-spacing: 15px;
    }
  }
}
@keyframes letterFadeIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
</style>
